import flower from "../../images/joinTheTeam/flower.png"
import mail from "../../images/joinTheTeam/mail.png"
import React from "react"
import classes from "./JoinTheTeam.module.scss"

const JoinTheTeamImages = () => {
  return (
    <>
      <div className={classes.flowerImageContainer}>
        <img src={flower} alt={"flower"} />
      </div>
      <div className={classes.mailImageContainer}>
        <img src={mail} alt={"mail"} />
      </div>
    </>
  )
}

export default JoinTheTeamImages
