import { graphql, useStaticQuery } from "gatsby"

export const useCareerQuery = () => {
  const { allVacanciesJson } = useStaticQuery(graphql`
    query MyQuery1 {
      allVacanciesJson {
        edges {
          node {
            id
            isPublished
            workPlaces {
              id
              place
            }
            name
            tags {
              id
              name
            }
          }
        }
      }
    }
  `)
  return allVacanciesJson
}
