import React, { useCallback, useState } from "react"
import TextInputWrapper from "src/components/forms/components/wrappers/TextInputWrapper"
import SubmitButton from "src/components/forms/components/inputs/SubmitButton"
import {
  FileInputDefaultSubscription,
  joinTheTeamFormValidation,
  yearsOfExperience,
} from "src/components/forms/constants"
import { useForm, FormProvider } from "react-hook-form"
import { ClearAllErrors, clearErrorByName } from "src/constants/functions"
import FileInputWrapper from "../components/wrappers/FileInputWrapper"
import classes from "./JoinTheTeamForm.module.scss"
import SelectInputWrapper from "../components/wrappers/SelectInputWrapper"
import { useCareerQuery } from "../../../hooks/useCareerQuery"
import useGetSearchParams from "../../../hooks/useGetSearchParams"
import { ToastContainer, toast } from "react-toastify"
import SuccessMessage from "../components/SuccessMessage"

const JoinTheTeamForm = () => {
  const params = useGetSearchParams()
  const data = useCareerQuery()
  const vacancies = data.edges
  const publishedVacancies = vacancies.filter(
    vacancy => vacancy.node.isPublished,
  )

  const otherPosition = {
    value: "Other position",
    label: "Other position",
  }

  const filteredVacancies = publishedVacancies.filter(item => {
    return item.node.workPlaces.filter(item => {
      if (params && params.city && item.place) {
        return item.place.toLowerCase().includes(params.city.toLowerCase())
      } else {
        return true
      }
    }).length
  }).length
    ? publishedVacancies
        .filter(item => {
          return item.node.workPlaces.filter(item => {
            if (params && params.city && item.place) {
              return item.place
                .toLowerCase()
                .includes(params.city.toLowerCase())
            } else {
              return true
            }
          }).length
        })
        .map(item => ({
          label: item.node.name,
          value: item.node.name,
        }))
    : publishedVacancies.map(item => ({
        label: item.node.name,
        value: item.node.name,
      }))
  filteredVacancies.push(otherPosition)

  const handleDeleteFile = useCallback(e => {
    document.getElementById("CV_uploader").value = ""
    e.preventDefault()
    setChosenFile(FileInputDefaultSubscription)
  }, [])

  const [chosenFile, setChosenFile] = useState(FileInputDefaultSubscription)
  const formMethods = useForm({ mode: "onChange" })

  const clearData = useCallback(() => {
    setChosenFile(FileInputDefaultSubscription)
    formMethods.reset({})
  }, [setChosenFile, formMethods])

  const handleSuccessSend = () => {
    toast.success(<SuccessMessage />)
  }

  const submitSendForm = useCallback(
    async ({
      name,
      surname,
      phoneNumber,
      email,
      vacancy,
      otherPosition,
      experience,
    }) => {
      const formData = new FormData()
      formData.append("first_name", name)
      formData.append("last_name", surname)
      formData.append("phone", phoneNumber)
      formData.append("email", email)
      formData.append("vacancy", otherPosition || vacancy)
      formData.append("experience", experience)
      document.getElementById("CV_uploader").files[0] &&
        formData.append(
          "cv_file",
          document.getElementById("CV_uploader").files[0],
        )
      try {
        const result = await fetch(
          `${process.env.GATSBY_HOST_URL}/scripts/adCv.php`,
          {
            method: "POST",
            mode: "cors",
            body: formData,
          },
        )

        if (!result.ok) {
          throw new Error(`${result.status} ${result.statusText}`)
        }

        handleSuccessSend()
        clearData()
      } catch (e) {
        console.log(e)
      }
    },
    [clearData],
  )

  const handleBlur = useCallback(
    e => clearErrorByName(e, formMethods.clearErrors),
    [formMethods.clearErrors],
  )

  return (
    <FormProvider {...formMethods}>
      <form
        className={classes.formWrapper}
        onSubmit={formMethods.handleSubmit(submitSendForm)}
        encType="multipart/form-data"
      >
        <TextInputWrapper
          type="text"
          name="name"
          label="FIRST NAME *"
          placeholder="Enter your name"
          validation={joinTheTeamFormValidation.name}
          isDark={true}
          isMoreSpace={true}
        />
        <TextInputWrapper
          type="text"
          name="surname"
          label="LAST NAME *"
          validation={joinTheTeamFormValidation.surname}
          placeholder="Enter your surname"
          isDark={true}
          isMoreSpace={true}
        />
        <TextInputWrapper
          type="text"
          name="phoneNumber"
          validation={joinTheTeamFormValidation.phone}
          label="PHONE NUMBER *"
          placeholder="+38 (095) 122 33 44"
          isDark={true}
          isMoreSpace={true}
        />
        <TextInputWrapper
          type="email"
          name="email"
          label="EMAIL *"
          placeholder="emailexample@gmail.com"
          validation={joinTheTeamFormValidation.email}
          onBlur={e => clearErrorByName(e, formMethods.clearErrors)}
          isDark={true}
          isMoreSpace={true}
        />
        <SelectInputWrapper
          label="VACANCY *"
          name="vacancy"
          validation={joinTheTeamFormValidation.vacancy}
          options={filteredVacancies}
          isDark={true}
          isMoreSpace={true}
        />
        {formMethods.getValues().vacancy === otherPosition.value && (
          <TextInputWrapper
            type="text"
            name="otherPosition"
            label="OTHER POSITION"
            placeholder="Enter your name"
            isDark={true}
            isMoreSpace={true}
          />
        )}
        <SelectInputWrapper
          label="YEARS OF EXPERIENCE"
          name="experience"
          options={yearsOfExperience}
          isDark={true}
          isMoreSpace={true}
        />
        <FileInputWrapper
          FileInputDefaultSubscription={FileInputDefaultSubscription}
          deleteFile={handleDeleteFile}
          chosenFile={chosenFile}
          setChosenFile={setChosenFile}
          name="CV"
          label="UPLOAD YOUR CV"
          accept=".pdf,.doc,.docx"
          onBlur={handleBlur}
          isDark={true}
          isMoreSpace={true}
        />
        <div
          style={{
            position: "relative",
          }}
        >
          <SubmitButton
            isDark={true}
            onClick={() => ClearAllErrors(formMethods.clearErrors)}
          >
            Send CV
          </SubmitButton>
          <ToastContainer
            className={classes.toast}
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </div>
      </form>
    </FormProvider>
  )
}

export default JoinTheTeamForm
