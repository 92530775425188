import classes from "../Forms.module.scss"
import React from "react"

const SuccessMessage = () => {
  return (
    <div className={classes.containerSuccessMessage}>
      <div className={classes.containerSuccessTitle}>
        <h3>Success</h3>
      </div>
      <div>
        <h5>We will get in touch with you soon!</h5>
      </div>
    </div>
  )
}

export default SuccessMessage
