import React from "react"
import clsx from "clsx"
import classes from "./JoinTheTeam.module.scss"
import JoinTheTeamForm from "../forms/JoinTheTeamForm/JoinTheTeamForm"
import useGetSearchParams from "../../hooks/useGetSearchParams"

const JoinTheTeamPage = () => {
  const params = useGetSearchParams()
  const city = (params && params.city) || "Zaporizhzhia"
  return (
    <section className={clsx("container", classes.root)}>
      <div>
        <h1 className={classes.title}>Join our team in {city}</h1>
      </div>
      <div className={classes.descriptionContainer}>
        <p className={classes.description}>
          It's never too late to become a rock star! Drum’N’Code’s team knows
          how to. Fill out the form below and we’ll contact you as soon as
          possible.
        </p>
      </div>
      <div className={`${classes.formContainer} twoColumnBlock`}>
        <div className="leftColumn">
          <h3 className={classes.sectionTitle}>Send your CV</h3>
        </div>

        <div className="rightColumn">
          <JoinTheTeamForm />
        </div>
      </div>
      <div className={`${classes.sectionContainer} twoColumnBlock`}>
        <div className="leftColumn">
          <h3 className={classes.sectionTitle}>About D'n'C</h3>
        </div>

        <div className={`${classes.shortTitleContainer} rightColumn`}>
          Drum’N’Code is an IT company from Zaporizhzhzia that has 5 years of
          experience, 80+ full-time engineers, 150 customers worldwide and 172
          successful projects. Join Drum’N’Code to become a part of the rockstar
          developer’s team!
        </div>
      </div>
    </section>
  )
}

export default JoinTheTeamPage
