import BasicLayout from "../components/layout/BasicLayout/BasicLayout"
import SEO from "../components/seo"
import React from "react"
import JoinTheTeamPage from "../components/JoinTheTeam/JoinTheTeam"
import JoinTheTeamImages from "../components/JoinTheTeam/JoinTheTeamImages"

const JoinTheTeam = () => {
  return (
    <BasicLayout stickyBtn={false}>
      <SEO title="Join The Team" />
      <JoinTheTeamPage />
      <JoinTheTeamImages />
    </BasicLayout>
  )
}

export default JoinTheTeam
